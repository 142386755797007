import { QuantitySelector, updateCartKitItem } from 'ui';
import styles from './BundleItem.module.scss';
import cn from 'classnames';
import { BundleItemEntry } from '../../types';

type BundleItemProps = {
    bundleItemEntry: BundleItemEntry;
    showInventoryPopup: (item: BundleItemEntry, ev: React.MouseEvent) => void;
    buttonLabel: string;
    buttonLabelAdded: string;
};

export const BundleItem = ({ bundleItemEntry, showInventoryPopup, buttonLabel, buttonLabelAdded }: BundleItemProps) => {
    const translations = (window as any).app.preloadState.translation;

    const maximumQuantity = bundleItemEntry.inventory?.maximumQuantity ?? 0;
    const minimumQuantity = bundleItemEntry.inventory?.minimumQuantity ?? 0;
    const packageQuantity = bundleItemEntry.inventory?.packageQuantity ?? 0;
    const quantityStep = bundleItemEntry.inventory?.quantityStep ?? 0;
    const isInInventory = bundleItemEntry.inventory?.warehouse?.isAvailable ?? false;
    const isInBranch = bundleItemEntry.inventory?.branch?.isAvailable ?? false;

    return (
        <div className={cn(styles.wrapper, styles.kitChildGroup)}>
            <a href={bundleItemEntry.itemUrl}>
                <div className={styles.group}>
                    <img className={cn(styles.mobileImage, "objectFitContain")} src={bundleItemEntry.imageUrl + "?width=160"} alt={bundleItemEntry.displayName} width="80" />
                    <div className={styles.infoBlock}>
                        <p className={styles.title}>{bundleItemEntry.displayName}</p>
                        <p className={cn(styles.articleNumber, "firstLetterCapital")}>
                            {bundleItemEntry.kitCode && <span className={styles.bundleEntrySubInfo}>{bundleItemEntry.kitCode && `${translations["serviceKitBundle.kitCode"]}: ${bundleItemEntry.kitCode}`}</span>}
                            {bundleItemEntry.serialNumber && <span className={styles.bundleEntrySubInfo}>{bundleItemEntry.serialNumber && `${translations["bundleItem.serialNumber"]}: ${bundleItemEntry.serialNumber}`}</span>}
                            {bundleItemEntry.code && <span className={styles.bundleEntrySubInfo}>{bundleItemEntry.code && `${translations["sparePartItem.articleNo"]}: ${bundleItemEntry.code}`}</span>}
                        </p>
                        {bundleItemEntry.labels?.length > 0 &&
                            <p className={cn(styles.labels)}>
                                {bundleItemEntry.labels.map(label =>
                                    <span key={label.text} className={styles.label}>{label.text}</span>
                                )}
                            </p>
                        }
                        <p className={styles.additionalInfo}>
                            {packageQuantity > 0 && <span className={"d-inline-block firstLetterCapital"}>{translations["sparePartItem.quantityFrp"]}: {packageQuantity}</span>}
                            {bundleItemEntry.itemUnitId && <span className={"d-inline-block firstLetterCapital"}>{translations["sparePartItem.unit"]}: {bundleItemEntry.itemUnitId}</span>}
                            <span
                                className={cn("d-md-none truckIcon", {
                                    [styles.close]: !isInInventory,
                                    [styles.truck]: isInInventory,
                                })}
                            >
                                {isInInventory ? <span dangerouslySetInnerHTML={{ __html: bundleItemEntry.inventory?.warehouse?.availabilityDisplay ?? "" }}></span> : <span className={"d-inline-block firstLetterCapital"}>{translations["inventoryPopup.notInStock"]}</span>}
                            </span>
                            <span
                                onClick={(ev) => showInventoryPopup(bundleItemEntry, ev)}
                                className={cn(styles.inventoryIndicator, "d-md-none", {
                                    [styles.check]: isInBranch,
                                    [styles.close]: !isInBranch,
                                })}
                            >
                                {bundleItemEntry.inventory?.branch?.branchName ?? translations["inventories.selectBranch"]}
                            </span>
                        </p>
                    </div>
                </div>
                <div className={styles.prices}>
                    {bundleItemEntry.percentageDiscount > 0 && (
                        <div className={styles.nmd}>
                            <p className={styles.discountedPrice}>{bundleItemEntry.discountedPrice}</p>
                        </div>
                    )}
                    <div>
                        <p
                            className={cn({
                                [styles.boldText]: bundleItemEntry.percentageDiscount <= 0,
                                [styles.originalPrice]: bundleItemEntry.percentageDiscount > 0,
                                [styles.discountedPrice]: bundleItemEntry.percentageDiscount > 0,
                                [styles.strike]: bundleItemEntry.percentageDiscount > 0,
                                [styles.smallFont]: bundleItemEntry.percentageDiscount > 0
                            })}
                        >
                            <span className={styles.nmd}>{bundleItemEntry.unitPrice}</span>
                        </p>
                        <span className={cn(styles.md_inline, styles.discountedPrice)}>{bundleItemEntry.discountedPrice ?? bundleItemEntry.unitPrice}</span>
                    </div>
                    {bundleItemEntry.percentageDiscount > 0 && (
                        <>
                            <div>
                                <span className={cn(styles.md_inline, styles.strike, styles.secondary)}>{bundleItemEntry.unitPrice}</span>
                            </div>
                            <div>
                                <div className={cn("firstLetterCapital d-inline-block", styles.secondary)}>{translations["sparePartItem.discount"]}:</div>
                                <div className="d-inline-block">
                                    <span className={styles.discount}>-{bundleItemEntry.percentageDiscount}%</span>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className={styles.availabilityIndicators}>
                    <span
                        className={cn("d-none d-md-block truckIcon", styles.smallFont, {
                            [styles.close]: !isInInventory,
                            [styles.truck]: isInInventory,
                        })}
                    >
                        {isInInventory ? <span dangerouslySetInnerHTML={{ __html: bundleItemEntry.inventory?.warehouse?.availabilityDisplay ?? "" }}></span> : <span className={"d-inline-block firstLetterCapital"}>{translations["inventoryPopup.notInStock"]}</span>}
                    </span>
                    <span
                        onClick={(ev) => showInventoryPopup(bundleItemEntry, ev)}
                        className={cn(styles.inventoryIndicator, "d-none d-md-block", styles.smallFont, styles.smallMarginTop, {
                            [styles.check]: isInBranch,
                            [styles.close]: !isInBranch,
                        })}
                    >
                        {bundleItemEntry.inventory?.branch?.branchName ?? translations["inventories.selectBranch"]}
                    </span>
                </div>
            </a>
            <div className={styles.quantity}>
                <QuantitySelector forceMinValue={false}
                    buttonLabel={buttonLabel}
                    buttonLabelAdded={buttonLabelAdded}
                    showLogin={bundleItemEntry.showLogin}
                    showNotAuthorized={bundleItemEntry.showNotAuthorized}
                    showRequestQuotationByEmail={bundleItemEntry.showRequestQuotationByEmail}
                    showContactSupport={bundleItemEntry.showContactSupport}
                    showBuyButton={bundleItemEntry.showBuyButton}
                    requestQuotationByEmail={bundleItemEntry.requestQuotationByEmail}
                    contactSupportLink={bundleItemEntry.contactSupportLink}
                    className={styles.buttonClass}
                    step={quantityStep}
                    min={minimumQuantity}
                    max={maximumQuantity}
                    initialValue={bundleItemEntry.quantity}
                    onRemove={(f: () => void) => updateCartKitItem(bundleItemEntry.code, 0, bundleItemEntry.bundleCode, bundleItemEntry.kitCode, bundleItemEntry.serialNumber).then(() => f())}
                    onChange={(quantity: number) => updateCartKitItem(bundleItemEntry.code, quantity, bundleItemEntry.bundleCode, bundleItemEntry.kitCode, bundleItemEntry.serialNumber)}
                    buttonClassName = "btn--outlined"
                ></QuantitySelector>
            </div>
        </div>

    );
};
